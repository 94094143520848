<template>
  <div>
    <!-- 搜索框-->
    <van-search v-model="selectCustomerValue" placeholder="请输入搜索关键词"/>
    <van-row>
      <van-col span="14" class="listRow">当前查询数据条数：{{ listRow }}条</van-col>
    </van-row>
    <!-- 列表-->
    <div ref="fresh" :style="{height: freshHeight +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model="loading"
              loading-text="加载中"
              :finished="finished"
              finished-text="没有更多了"
              @load="nextPage"
              style="margin-bottom: 45px"
          >
            <mail-list-cell v-for="(item,index) in list" v-bind:key="item.id" :data="item"
                            :style="{marginBottom: index == list.length-1 ? '0px' : '0px'}"/>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!--    <Add to="/FirstAddressContact"></Add>-->
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import MailListCell from '@/components/MailListCell'
import Add from "@/components/Add";

export default {
  name: "MailList",
  components: {CrmTabBar, MailListCell, Add},
  data() {
    return {
      colors: '',
      list: [],
      listRow: '',
      selectCustomerValue: '',
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      currentPage: 1, // 当前页码
      pageSize: 20, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
    }
  },
  watch: {
    //监听公司名头
    'selectCustomerValue': function () {
      this.list = [];
      this.onRefresh();
    },
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 70
    this.onRefresh();
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 下拉刷新
    getData() {
      this.loading = true
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getMailInfo.json',
          {
            crmCustomerid: '',
            customerName: this.selectCustomerValue,
            current: this.currentPage,
            size: this.pageSize,
          }).then(res => {
        this.loading = false
        if (res.status == 200) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res.data)
          res.data.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.data.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
  }
}
</script>

<style scoped>
.listRow {
  fontSize: '15px'
}
</style>
