<template>
    <div class="add">
        <van-button class="add_button" color="#FD843E" icon="plus" :to="to + param"/>
    </div>
</template>

<script>
    export default {
        name: "Add",
        props: {
            to: String,
            param: String
        }
    }
</script>

<style scoped>
    .add .add_button {
        position: fixed;
        top: 75%;
        left: 80%;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background: #F98433;
        z-index: 9999;
    }
</style>
