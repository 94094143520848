<template>
  <div class="customer-cell">
    <van-row>
      <van-col offset="2" span="5" style=" text-align: left">
        <van-icon name="manager" color="#6390E3" style="top: 1px;"/>
        {{ data.contacts }}
      </van-col>
      <van-col offset="7" span="10" class="tag van-ellipsis" :style="{color:'#B9B9C0', fontSize:'12px'}">
        {{ data.customerName }}
      </van-col>
    </van-row>
    <van-row :style="{marginTop:'5px'}">
      <van-col offset="3" span="8" class="text van-ellipsis info" style="text-align: left;color: #6390E3"
               @click="onClickTel(data.contactsPhone)">
        {{ data.contactsPhone }}
        <van-icon name="phone-o" color="#6390E3" style="top: 3px;"/>
      </van-col>
      <van-col offset="5" span="8" :style="{color:'#6390E3', fontSize:'12px',marginTop:'3px'}"
               @click="to(data.crmCustomerid)">
        查看详情
      </van-col>
    </van-row>

  </div>
</template>

<script>
export default {
  name: "MailListCell",
  methods: {
    to(crmCustomerid) {
      if(crmCustomerid.length<=0){
        this.$message.warning('暂无详情！');
      }else{
        this.$router.push('MailListDetail/' + crmCustomerid);
      }
    },
    onClickTel(tel) {
      window.location.href = 'tel://' + tel
    }
  },
  props: {
    data: Object
  }
}
</script>

<style scoped>
.customer-cell {
  background: #ffffff;
  margin-top: 4px;
  border-radius: 4px;
  padding-bottom: 8px;
}

.customer-cell .circle {
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: #3B71BE;
  color: white;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
  transform: translate(0%, 0%);
}

.customer-cell .circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.customer-cell .circle span {
  display: inline-block;
  vertical-align: middle;
}

.customer-cell .text {
  text-align: left;
}

.customer-cell .title {
  font-weight: 400;
  font-size: 18px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .info {
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .user {
  font-weight: 400;
  font-size: 14px;
  color: #318EF5;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.customer-cell .tag {
  font-weight: 700;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

</style>

<style>
.van-list .customer-cell .van-row:first-child {
  padding-top: 20px;
}
</style>
